import { APP_NAME, CONTACT_EMAIL } from "@/config";

export function PrivacyPolicyPage() {
  return (
     <div className="flex h-full max-w-full">
            <section className="mx-auto h-full w-screen">
               <div className="container mx-auto lg:max-w-xl justify-evenly prose">
                  <h1>{APP_NAME} Privacy Policy</h1>

               <p><strong>Effective Date:</strong> 22/10/2024</p>

    <p>At <strong>{APP_NAME}</strong>, we value your privacy and are committed to protecting your personal and health data. This Privacy Policy explains how we collect, use, store, and share your information when you use our services.</p>

    <h2>1. Information We Collect</h2>
    <p>We collect the following types of information:</p>
    <ul>
        <li><strong>Personal Information:</strong> Your email address  when you sign up for our services.</li>
        <li><strong>Health Data:</strong> Blood test results and health metrics uploaded in PDF format.</li>
        <li><strong>Usage Data:</strong> Technical information such as your device type, operating system, and interaction with our app.</li>
    </ul>

    <h2>2. How We Use Your Information</h2>
    <p>We use your information for the following purposes:</p>
    <ul>
        <li><strong>To Provide the Service:</strong> We process your blood test PDFs to convert them into a simple format.</li>
        <li><strong>OpenAI API Processing:</strong> We use third-party APIs to assist in analyzing your data. Health data is temporarily shared with them solely for processing and is not retained after analysis.</li>
    </ul>

    <h2>3. Data Sharing & Disclosure</h2>
    <p>We only share your data under the following circumstances:</p>
    <ul>
        <li><strong>Third-Party Providers:</strong> We use third-party services to process your health data and other third-party services for app functionality, including hosting and analytics. These services only access your data as needed to provide their services to us.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required by law or in response to lawful requests from public authorities.</li>
    </ul>

    <h2>4. Data Storage & Security</h2>
    <p>We take the security of your data seriously. Your personal and health data is stored securely in our cloud infrastructure with encryption and other safeguards to prevent unauthorized access.</p>

    <h2>5. Your Rights Over Your Data</h2>
    <p>You have control over your data and can:</p>
    <ul>
        <li><strong>Access & Export Your Data:</strong> View and download your data directly from the app.</li>
        <li><strong>Delete Your Data:</strong> Request the deletion of your account and all associated data at any time. Simply contact us at <a href={`mailto: ${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a></li>
    </ul>

    <h2>6. Data Retention</h2>
    <p>We retain your data for as long as your account is active or as necessary to provide our services. You can request the deletion of your data at any time, and we will comply with such requests promptly.</p>

    <h2>7. Children’s Privacy</h2>
    <p>Our service is not intended for children under the age of 13. It is intended for people processing their own personal data only. We do not knowingly collect personal data from children.</p>

    <h2>8. International Data Transfers</h2>
    <p>We may transfer and store your data in countries outside your residence, ensuring adequate protections in line with applicable laws.</p>

    <h2>9. Changes to This Policy</h2>
    <p>We may update this Privacy Policy from time to time. Significant changes will be posted in the app, and we will notify you via email if needed.</p>

    <h2>10. Contact Us</h2>
    <p>If you have any questions or concerns about your privacy or this policy, please contact us at <a href={`mailto: ${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>.</p>
</div>
         </section>
     </div>

  )
}
