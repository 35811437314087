import { APP_NAME, CONTACT_EMAIL } from "@/config";


export function TermsOfServicePage() {
  return (
     <div className="flex h-full max-w-full">
            <section className="mx-auto h-full w-screen">
               <div className="container mx-auto lg:max-w-xl justify-evenly prose">
               <h1>{APP_NAME} Terms of Service</h1>

    <p>Welcome to <strong>{APP_NAME}</strong>. By using our app or services, you agree to the following Terms of Service. Please read them carefully before accessing or using the app.</p>

    <h2>1. Acceptance of Terms</h2>
    <p>By creating an account, accessing, or using {APP_NAME}, you agree to comply with and be bound by these Terms of Service, as well as our Privacy Policy. If you do not agree to these terms, please do not use the app.</p>

    <h2>2. Description of Service</h2>
    <p>{APP_NAME} provides users with the ability to upload, store, and analyze blood test results in PDF format, converting them into easy-to-read tables for tracking health data. Our service may also provide features to track health metrics over time, compare data from different providers, and share data with healthcare professionals.</p>

    <h2>3. User Responsibilities</h2>
    <p>As a user, you are responsible for:</p>
    <ul>
        <li>Keeping your account credentials secure and not sharing your login details with others.</li>
        <li>Not using {APP_NAME} for any illegal or unauthorized purposes.</li>
    </ul>

    <h2>4. Health Data and Usage</h2>
    <p>{APP_NAME} processes health data based on the PDFs you upload. This data is used to provide you with the service of converting test results into tables, analyzing trends, and offering tracking tools. You are responsible for the accuracy and legality of the data you upload.</p>
    <p>You may not upload health data belonging to anyone else other than your own.</p>

    <h2>5. Account Termination</h2>
    <p>We reserve the right to suspend or terminate your account if you violate these Terms of Service, engage in fraudulent activities, or misuse the app in any way. You can also terminate your account at any time by contacting us or through the app settings. Upon termination, your data will be deleted as per our Privacy Policy.</p>

    <h2>6. Intellectual Property</h2>
    <p>All intellectual property related to {APP_NAME}, including the app design, features, content, and branding, is owned by us or licensed to us. You are granted a limited, non-exclusive, and non-transferable right to use the app solely for personal use, and you may not modify, distribute, or reproduce any part of the app without our permission.</p>

    <h2>7. Third-Party Services</h2>
    <p>{APP_NAME} may use third-party services such as OpenAI to process your health data. These services are used solely to provide functionality and are governed by their respective terms and privacy policies. We are not responsible for the practices of third-party service providers.</p>

    <h2>8. Limitation of Liability</h2>
    <p>{APP_NAME} is provided "as-is" without any warranties. We are not liable for any inaccuracies in the health data uploaded, or for any direct, indirect, incidental, or consequential damages resulting from your use of the app, including any loss of data or unauthorized access to your account.</p>

    <h2>9. Indemnification</h2>
    <p>You agree to indemnify and hold harmless {APP_NAME}, its affiliates, employees, and partners from any claims, liabilities, damages, losses, or expenses arising from your use of the app, violation of these Terms of Service, or breach of any rights of third parties.</p>

    <h2>10. Modifications to the Terms</h2>
    <p>We may modify these Terms of Service from time to time. Any changes will be posted within the app, and significant updates will be communicated via email. Continued use of {APP_NAME} after such changes signifies your acceptance of the new terms.</p>

    <h2>11. Governing Law</h2>
    <p>These Terms of Service are governed by and construed in accordance with the laws of the EU. Any legal disputes arising from these terms or the use of {APP_NAME} will be subject to the exclusive jurisdiction of EU courts.</p>

    <h2>12. Contact Us</h2>
    <p>If you have any questions regarding these Terms of Service, please contact us at  <a href={`mailto: ${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>.</p>
    </div>
         </section>

     </div>

  )
}
