import ReactGA from "react-ga";

interface EventParams {
    action: string;
    label?: string; // label is optional
  }

export const useAnalyticsEventTracker = (category: string) => {
    const eventTracker = ({ action, label }: EventParams): void => {
        ReactGA.event({ category, action, label });
      };
    return {eventTracker};
};
