type RangeStringProps = {
    range_less_than: number | null;
    range_greater_than: number | null;
};
export const RangeString = (props: RangeStringProps) => {
    return ( <>
            {props.range_less_than != null && props.range_greater_than != null && `${props.range_greater_than} - ${props.range_less_than}`}
            {props.range_less_than != null && props.range_greater_than === null && `< ${props.range_less_than}`}
            {props.range_less_than === null && props.range_greater_than != null && `> ${props.range_greater_than}`}
            </>
    );
};