import React from "react";
import { Header } from "./Header";

export const Layout: React.FC<{ children: React.ReactNode }> = ({children}) => (
    <div className="h-screen flex flex-col">
      {/* Header */}
      <Header/>
      {/* Sidebar */}
      {/* Main content */}
      <main className="h-screen overflow-y-auto">
        {/* {children} */}
        <div className="w-screen h-full mx-auto">
          {children}
        </div>
      </main>

      {/* Footer */}
      <footer className="text-white p-4 bg-zinc-900">
        <p>&copy; 2024 JEMA</p>
      </footer>
    </div>
  );
