import { csrfTokenAction } from "@/actions/csrfAction";
import Cookies from "js-cookie";

export const CSRFTokenProvider = {
  async getCSRFToken() {
    const CSRFtoken = Cookies.get("csrftoken");
    if (!CSRFtoken) {
      const token = await (await csrfTokenAction()).json();
      this.setCSRFToken(token.csrfToken);
    }
    return Cookies.get("csrftoken") || null;
  },

  setCSRFToken(CSRFtoken: string | null) {
    if (CSRFtoken) {
      Cookies.set("csrftoken", CSRFtoken, { expires: 7 });
    } else {
      Cookies.remove("csrftoken");
    }
  },
};
