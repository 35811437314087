import { useEffect, useState, useRef } from "react";
import { useCategory } from "@/context/category/useCategory";
import { CSRFTokenProvider } from "@/auth/CSRFTokenProvider";
import { Chart } from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Line } from "react-chartjs-2";
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-moment';
import { ChartConfig, ChartContainer } from "@/components/ui/chart"

// register plugins
Chart.register(CategoryScale);
Chart.register(annotationPlugin);
Chart.register(ChartDataLabels);

export function LineChart() {
  const chartRef = useRef(null);
  const { activeCategory } = useCategory();

  const [chartData, setChartData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';

      let url = `${import.meta.env.VITE_HOST}/api/chart/`
      if (activeCategory) {
        url += `?category=${encodeURIComponent(activeCategory)}`
      }
      const data = await fetch(url, {
        method: "GET",
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrftoken,
        },
      }).then(r => r.json());

      setChartData(data);
    }
    fetchData();
  }, [activeCategory]);

  if (!chartData) {
    return '';
  }

  const chartConfig = {} satisfies ChartConfig

  return (
    <ChartContainer config={chartConfig} className="min-h-[200px] min-h-[600px] h-full w-full">
      <Line
        ref={chartRef}
        data={chartData}
        options={{
            parsing: {
              xAxisKey: 'datetime',
              yAxisKey: 'relative_value'
            },
            onHover: (_, element, chart) => {
                // dynamic y-axis
                if (chart.options.scales && chart.options.scales.y && chart.options.scales.y.ticks) {
                    if (element.length > 0) {
                        const dataPoint = chart.data.datasets[element[0].datasetIndex].data[element[0].index] as {x:number, y:number, name: string, range_less_than: number, range_greater_than: number};

                        if (dataPoint) {
                            chart.options.scales.y.ticks.callback = function(value) {
                                const relativeValue = value as number;
                                const absoluteValue = relativeValue * (dataPoint.range_less_than - dataPoint.range_greater_than) + dataPoint.range_greater_than;
                                return Math.round((absoluteValue + Number.EPSILON) * 100) / 100;
                            };
                            (chart.options.scales.y as {title: {text: string}}).title.text = dataPoint.name;
                            chart.update();
                        }
                    } else {
                        chart.options.scales.y.ticks.callback = (value: string | number) => value;
                        (chart.options.scales.y as {title: {text: string}}).title.text = 'Value relative to normal range';
                        chart.update();
                    }
                }
            },
            layout: {
                padding: {
                    left: 0,
                    right: 50,
                    top: 50,
                    bottom: 50
                }
            },
            scales: {
                y: {
                    display: true,
                    position: 'left',
                    grid: {
                        drawOnChartArea: false
                    },
                    title: {
                        display: true,
                        text: 'Value relative to normal range',
                        font: {
                          size: 16
                        }
                    },
                    afterFit: function(axis) {
                        axis.width = 80;
                    }
                },
                x: {
                    grid: {
                        drawOnChartArea: false
                    },
                    offsetAfterAutoskip: true,
                    type: 'time',
                    offset: true,
                    ticks:{
                        source:'data'
                    },
                    time: {
                      tooltipFormat: "MMM DD, yyyy - HH:mm",
                      unit: "day",
                      minUnit: "minute",
                    },
                }
            },
            plugins: {
                legend: {
                    position: 'bottom',
                    align: 'start',
                    labels: {
                        pointStyle: 'rect',
                        usePointStyle: true,
                    }
                },
                datalabels: {
                    formatter: function (value) {
                        return value.relative_value;
                    },
                    labels: {
                        title: null
                    }
                },
                tooltip: {
                    mode: 'index',
                    intersect: true,
                    position: 'nearest',
                    filter: function (tooltipItem) {
                        if (tooltipItem.chart.tooltip) {
                            const pointY = tooltipItem.chart.tooltip.caretY;
                            const itemY = tooltipItem.element.y;
                            return Math.abs(itemY - pointY) < 5;
                        }

                        return true
                    },
                    callbacks: {
                       label: function(context) {
                            const raw = context.raw as {
                                value_float: number;
                                units: string,
                                range_less_than: number;
                                range_greater_than: number;
                            };
                            let label = context.dataset.label || '';
                            let range = '';

                            if (label) {
                                label += ': ';
                            }
                            if (raw.value_float !== null) {
                                if (raw.range_greater_than !== null && raw.range_less_than !== null) {
                                    range = raw.range_greater_than + ' - ' + raw.range_less_than;
                                } else if (raw.range_greater_than !== null) {
                                    range = '> ' + raw.range_greater_than;
                                } else if (raw.range_less_than !== null) {
                                    range = '< ' + raw.range_less_than;
                                }

                                label += raw.value_float + ' ' + raw.units + ' (' + range + ')';
                            }
                            return label;
                        }
                    }
                },
                annotation: {
                    annotations: {
//                         rangeHigh: {
//                             type: 'line',
//                             yMin: 1,
//                             yMax: 1,
//                             borderColor: 'red',
//                             borderWidth: 2,
//                             label : {
//                                 content : "Range High",
//                                 display : true,
//                                 position : "end"
//                             }
//                         },
//                         rangeLow: {
//                             type: 'line',
//                             yMin: 0,
//                             yMax: 0,
//                             borderColor: 'red',
//                             borderWidth: 2,
//                             label : {
//                                 content : "Range Low",
//                                 display : true,
//                                 position : "end"
//                             }
//                         },
//                         boxHigh: {
//                             type: 'box',
//                             yMin: 1,
//                             backgroundColor: 'rgba(255, 99, 132, 0.1)'
//                         },
                        boxNormal: {
                            type: 'box',
                            yMax: 1,
                            yMin: 0,
                            backgroundColor: 'rgba(144, 238, 144, 0.2)',
                            borderColor: 'rgba(144, 238, 144, 0.2)',
                            label: {
                                content: 'Normal Range',
                                display: true,
                                font: {size: 12},
                                position: {x: 'start', y: 'center'},
//                                 rotation: 270,
                            }
                        },
//                         boxLow: {
//                             type: 'box',
//                             yMax: 0,
//                             backgroundColor: 'rgba(255, 99, 132, 0.1)'
//                         }
                    }
                }
            }
        }}
      />
    </ChartContainer>
  );
}
