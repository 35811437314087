import React from "react";
import { Button } from "@/components/ui/button";
import { useCategory } from "@/context/category/useCategory";
import { useReport } from "@/hooks/useReport";

export const CategoryList: React.FC = () => {
  const { setActiveCategory } = useCategory();
  const { markers, error } = useReport();

  if (error) {
    console.log("error", error);
    return <></>;
  }
  const categories: string[] = []

  if (!markers) {
    return <></>;
  }
  
  markers.forEach((marker: Marker) => {
      if (!categories.includes(marker.category)) {
        categories.push(marker.category)
      }
    })



  const handleCategoryChange = (category: string) => {
        setActiveCategory(category);
  };


  return (
    <div className="flex flex-col space-y-2">
    { !!categories.length && <h2 className="relative text-left text-lg font-semibold tracking-tight">Categories</h2> }
              {categories?.map((name, i) => (
                <Button
                  variant="ghost"
                  className="p-2 w-full"
                  key={`${name}-${i}`}
                  onClick={() => handleCategoryChange(name)}
                >
                {name}
                </Button>
              ))}
            </div>
  );
}

