import { useState } from "react";
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { CSRFTokenProvider } from "@/auth/CSRFTokenProvider";


const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
})

export const PasswordResetForm: React.FC = () => {
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
    }
  })

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
      const response = await fetch(`${import.meta.env.VITE_HOST}/api/auth/password/reset/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        setError("Failed to reset password. Please try again.");
      }
    } catch (error) {
      console.error("Password reset failed:", error);
      setError("Password reset failed. Please try again later.");
    }
  }

  if (submitted) {
    return <p>Please check your email for password reset instructions.</p>;
  }

  return (
    <Form {...form}>
    <form onSubmit={form.handleSubmit(handleSubmit)} className="shadow-md rounded px-8 pt-6 pb-8 mb-4 space-y-6">
      <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
      />
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
      <Button type="submit">Submit</Button>
    </form>
    </Form>
  );
};