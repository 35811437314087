import { APP_NAME } from "@/config";
import { useAnalyticsEventTracker } from "@/hooks/useAnalyticsEventTracker";

export const MainSection = () => {
  const {eventTracker} = useAnalyticsEventTracker('main_section');
  return (
<section id='home' className="mx-auto h-full w-screen lg:flex lg:items-center bg-white text-black">
<div className="mx-auto text-center	">
  <h1
    className="bg-gradient-to-r from-zinc-600 via-blue-800 to-zinc-900 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl"
  >
    Track your health test results.
  </h1>

  <div className="flex  border-2">
  </div>
  <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
      Upload PDFs of your lab reports, and {APP_NAME} instantly converts them into easy-to-read, organized tables.
  </p>


  <div className="mt-8 flex flex-wrap justify-center gap-4">
    <a
      className="block w-full rounded border border-blue-600 bg-blue-600 px-12 py-3 text-sm text-white font-bold hover:bg-transparent hover:text-black focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
      href="/login"
      onClick={() => eventTracker({action: 'get_started'})}
    >
      Get Started
    </a>

    {/* <a
      className="block w-full rounded border border-blue-600 px-12 py-3 text-sm font-medium hover:bg-blue-600 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
      href="#learn-more"
    >
      Learn More
    </a> */}
  </div>
</div>
</section>
)};
