import React, { useState } from "react";
import { CategoryContext } from "./CategoryContext";

export const CategoryProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeCategory, setActiveCategory] = useState<Category>();
  const [categories, setCategories] = useState<Category[]>();

  return (
    <CategoryContext.Provider value={{ activeCategory, setActiveCategory, categories, setCategories }}>
      {children}
    </CategoryContext.Provider>
  );
};
