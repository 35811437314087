import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CSRFTokenProvider } from "@/auth/CSRFTokenProvider";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";


// Define the Price interface
interface Price {
  id: string;
  unit_amount: number;
  currency: string;
  recurring?: {
    interval: string;
  };
  product: {
    name: string;
  };
}

export const Prices = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [prices, setPrices] = useState<Price[]>([]);

  useEffect(() => {
    const fetchPrices = async () => {
      const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
      const { prices } = await fetch(`${import.meta.env.VITE_HOST}/api/payments/prices/`, {
        method: "GET",
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrftoken,
        },
      }).then(r => r.json());
      setPrices(prices);
    };
    fetchPrices();
  }, []);

  const createSubscription = async (priceId: string) => {
    const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
    const { subscriptionId, clientSecret } = await fetch(`${import.meta.env.VITE_HOST}/api/payments/create-subscription/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      body: JSON.stringify({
        priceId
      }),
    }).then(r => r.json());

    navigate('/subscribe', {
      state: {
        from: location,
        subscriptionId,
        clientSecret,
      },
      replace: false
    });
  }

  return (
    <div className="card flex flex-col items-center gap-4">
      <h1 className="text-3xl">Select a plan</h1>

      <div id="prices" className="flex flex-wrap gap-4">
        {prices.map((price) => {
          return (
            <Card key={price.id}>
                <CardHeader>
                    <CardTitle>{price.product.name}</CardTitle>
                    <CardDescription>{price.unit_amount / 100} {price.currency} {price.recurring ? `/ ${price.recurring.interval}` : ''}</CardDescription>
                </CardHeader>
                <CardContent>
                  <Button onClick={() => createSubscription(price.id)}>
                    Select
                  </Button>
                </CardContent>
            </Card>
          )
        })}
      </div>
    </div>
  );
}
