// @todo this isn't very responsive atm

import { logoutAction } from "@/actions/authActions";
import { TitleLink } from "@/components/navigation/TitleLink";
import { useAuth } from "@/context/Auth/useAuth";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";


export const Header: React.FC = () => {
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  // @todo this should make a request to the server to log out
  const logout = async () => {
    try {
      const res = await logoutAction();
      if (res.ok) {
        setIsAuthenticated(false);
        window.location.reload();
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (<header className="text-white p-4 bg-zinc-900 flex justify-between items-center">
    <div className="flex-1 text-left">
      {isAuthenticated && <Link to="/subscriptions"><Button variant="secondary">Subscriptions</Button></Link>}
      {!isAuthenticated && <Link to="/login"><Button variant="secondary">Login/Sign Up</Button></Link>}
    </div>
    <div className="flex-1 flex flex-col items-center justify-center">
      <TitleLink />
      <sub className="text-sm">Health test result tracking made simple</sub>
    </div>
    <div className="flex-1 text-right">
      {isAuthenticated && <Button variant="secondary" onClick={() => logout()}>Logout</Button>}
    </div>
  </header>)
}
