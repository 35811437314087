import { getReportAction } from "@/actions/reportActions";
import { useQuery } from "@tanstack/react-query";
import { useCategory } from "@/context/category/useCategory";
import  { useAuth } from "@/context/Auth/useAuth";
import { useNavigate } from "react-router-dom";


export const useReport = () => {
    const { activeCategory } = useCategory();
    const { setIsAuthenticated } = useAuth();
    const navigate = useNavigate();

    const queryFn =  () => getReportAction()
    const { data: markers, isPending, error, isError } = useQuery({
    queryKey: ["report"],
    queryFn: queryFn
  });

  if (isError) {
    if (error.message === "Unauthorized") {
      setIsAuthenticated(false);
      navigate("/login");
    }
  }


    // Produce a list of unique dates and names for the active category
    const allDates: string[] = []
    const names: string[] = []
    if (markers) {
        markers.map((marker: Marker) => { 
        if (marker.category === activeCategory) {
            if (!allDates.includes(marker.datetime)) {
            allDates.push(marker.datetime)
            }
            
            if (!names.includes(marker.name)) {
            names.push(marker.name)
            }
        }
        })
    }

  return { markers, allDates, names, isPending, error }
}

