import { useLocation, useNavigate } from 'react-router-dom';
import { CSRFTokenProvider } from "@/auth/CSRFTokenProvider";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";


export const Cancel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const subscription = location.state.subscription;

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
    await fetch(`${import.meta.env.VITE_HOST}/api/payments/cancel-subscription/`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      body: JSON.stringify({
        subscriptionId: subscription.id
      }),
    })

    navigate('/subscriptions', { replace: true });
  };

  return (
    <Card>
        <CardHeader>
            <CardTitle>Cancel {subscription.plan.product.name}</CardTitle>
            <CardDescription>
                <p>Status: {subscription.status}</p>
                <p>Current period end: {(new Date(subscription.current_period_end * 1000).toLocaleString())}</p>
            </CardDescription>
        </CardHeader>
        <CardContent>
            <Button variant="destructive" onClick={handleClick}>Cancel</Button>
        </CardContent>
    </Card>
  );
}
