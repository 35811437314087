import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: `${import.meta.env.VITE_DSN}`,
  integrations: [
  ],
  environment: `${import.meta.env.VITE_MODE}`,
});


ReactDOM.createRoot(document.getElementById("root")!).render(<App/>);
