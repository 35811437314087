// https://docs.stripe.com/sdks/stripejs-react
import { Prices } from "@/components/payments/Prices";
import { Subscriptions } from "@/components/payments/Subscriptions";
import { useSubscription } from "@/hooks/useSubscription";

export function SubscriptionsPage() {
  const { isSubscribed } = useSubscription();
  
  return (
    <div className="flex h-full max-w-full justify-center items-center">
        <div className="flex items-start">
            {!isSubscribed && <Prices />}
            <Subscriptions />
        </div>
    </div>
  )
}
