import { useState, useEffect } from 'react';
import { CSRFTokenProvider } from "@/auth/CSRFTokenProvider";
import { Link } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

// Define the Subscription interface
interface Subscription {
  id: string;
  status: string;
  current_period_end: number;
  plan: {
      product: {
      name: string;
    };
  };
  default_payment_method?: {
    card?: {
      last4: string;
    };
  };
}

export const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
      const { subscriptions } = await fetch(`${import.meta.env.VITE_HOST}/api/payments/subscriptions/`, {
        method: "GET",
        credentials: 'include',
        headers: {
          'X-CSRFToken': csrftoken,
        },
      }).then(r => r.json());

      setSubscriptions(subscriptions.data);
    }
    fetchData();
  }, []);

  if (subscriptions.length === 0) {
    return '';
  }

  return (
    <div className="card flex flex-col items-center gap-4">
      <h1 className="text-3xl">Subscriptions</h1>

      <div id="subscriptions" className="space-y-4">
        {subscriptions.map((subscription) => {
          return (
            <Card key={subscription.id}>
                <CardHeader>
                    <CardTitle>{subscription.plan.product.name}</CardTitle>
                    <CardDescription>
                        <p>Status: {subscription.status}</p>
                        <p>Current period end: {(new Date(subscription.current_period_end * 1000).toLocaleString())}</p>
                    </CardDescription>
                </CardHeader>
                <CardContent>
                  {subscription.status === 'active' && (
                    <Link to={'/cancel'} state={{ subscription }}><Button variant="destructive">Cancel</Button></Link>
                  )}
                </CardContent>
            </Card>
          )
        })}
      </div>
    </div>
  );
}
