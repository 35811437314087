import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form"
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { CSRFTokenProvider } from "@/auth/CSRFTokenProvider";


const formSchema = z
  .object({
    new_password1: z.string().min(8, { message: "Password must be at least 8 characters" }),
    new_password2: z.string(),
  })
  .refine((data) => data.new_password1 === data.new_password2, {
    message: "Passwords don't match",
    path: ["new_password2"], // path of error
  });

export const PasswordResetConfirmForm: React.FC = () => {
  const urlParams = useParams<{ uid: string; token: string }>();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      new_password1: '',
      new_password2: '',
    }
  })

  async function handleSubmit(values: z.infer<typeof formSchema>) {
    try {
      const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
      const response = await fetch(`${import.meta.env.VITE_HOST}/api/auth/password/reset/confirm/${urlParams.uid}/${urlParams.token}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({...values, ...urlParams}),
      });

      if (response.ok) {
        navigate("/login");
      } else {
        setError("Failed to reset password. Please try again.");
      }
    } catch (error) {
      console.error("Password reset failed:", error);
      setError("Password reset failed. Please try again later.");
    }
  }

  return (
    <Form {...form}>
    <form onSubmit={form.handleSubmit(handleSubmit)} className="shadow-md rounded px-8 pt-6 pb-8 mb-4 space-y-6">
      <FormField
          control={form.control}
          name="new_password1"
          render={({ field }) => (
            <FormItem>
              <FormLabel>New password</FormLabel>
              <FormControl>
                <Input placeholder="******************" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
      />
      <FormField
          control={form.control}
          name="new_password2"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Repeat new password</FormLabel>
              <FormControl>
                <Input placeholder="******************" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
      />
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
      <Button type="submit">Submit</Button>
    </form>
    </Form>
  );
};