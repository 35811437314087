import React, { useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import { isAuthenticatedCheck, setIsAuthenticatedCheck } from "@/auth/isAuthenticatedCheck";

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticatedFlag] = useState<boolean>();

  const setIsAuthenticated = async (isAuthenticatedFlag: boolean) => {
    setIsAuthenticatedCheck(isAuthenticatedFlag);
    setIsAuthenticatedFlag(isAuthenticatedFlag);
  }

  useEffect(() => {
    const checkAuth = async () => {
      setIsAuthenticated(await isAuthenticatedCheck());
    };
    checkAuth();
    }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated}}>
      {children}
    </AuthContext.Provider>
  );
};
