
import { Link } from "react-router-dom"
import { APP_NAME } from "@/config"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVials } from '@fortawesome/free-solid-svg-icons'

export const TitleLink: React.FC = () => (
    <Link to={"/"} className="flex items-center text-lg font-medium hover:text-secondary">
        <FontAwesomeIcon icon={faVials} /> 
        <h1 className="px-2">{APP_NAME}</h1>
    </Link>
)
