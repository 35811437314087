
import { Layout } from "@/layouts";
import { Outlet } from "react-router-dom";
import { AppProvider } from "@/context/AppProvider.tsx";
import { AuthProvider } from "@/context/Auth/AuthProvider";
import * as Sentry from "@sentry/react";


export const AppLayout = () => (
    <Sentry.ErrorBoundary>
        <AppProvider>
          <Layout>
              <Outlet/>
          </Layout>
        </AppProvider>
    </Sentry.ErrorBoundary>
);

// Use as little context as possible in the landing page
export const LandingLayout = () => (
    <Sentry.ErrorBoundary>
        <AuthProvider>
            <Layout>
                <Outlet/>
            </Layout>
        </AuthProvider>
    </Sentry.ErrorBoundary>
);
