import { CSRFTokenProvider } from "@/auth/CSRFTokenProvider";

interface LoginProps {
  email: string;
  password: string;
}

export const loginAction = async (loginProps: LoginProps) => {
  const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';

  return fetch(`${import.meta.env.VITE_HOST}/api/auth/login/`, {
    method: "POST",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      'X-CSRFToken': csrftoken,
    },
    body: JSON.stringify(loginProps),
  })};


export const logoutAction = async () => {

  const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';

  return fetch(`${import.meta.env.VITE_HOST}/api/auth/logout/`, {
    method: "POST",
    credentials: 'include',
    headers: {
      "Content-Type": "application/json",
      'X-CSRFToken': csrftoken,
    }
  })};

interface SignUpProps {
  email: string;
  password1: string;
  password2: string;
}

export const signupAction = async (signUpProps: SignUpProps) => {
  const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
  return fetch(`${import.meta.env.VITE_HOST}/api/auth/registration/`, {
    method: "POST",
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken,
    },
    body: JSON.stringify(signUpProps),
  });
}


export const isAuthenticatedAction = async () => {
  const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
  return fetch(`${import.meta.env.VITE_HOST}/api/session`, {
    method: "GET",
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken,
    },
  });
}

export const googleAuthAction = async (credentials:{
  credential: string,
  clientId: string,
  select_by: string,
}) => {
  console.log(credentials)
  const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
  return fetch(`${import.meta.env.VITE_HOST}/api/auth/google/`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
    },
    body: JSON.stringify({access_token: credentials.credential, id_token: credentials.credential}),
})};


export const getSubscriptionEndAction = async () => {
  const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';
  return fetch(`${import.meta.env.VITE_HOST}/api/subscription-end/`, {
    method: "GET",
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken,
    },
  });
}
