import { LoginForm } from "./LoginForm"
import { SignUpForm } from "./SignUpForm"
import { GoogleLogin } from '@react-oauth/google';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { useGoogleLogin } from "@/hooks/useGoogleLogin";
import { useEffect } from "react";
import { Terms } from "@/components/account"


export function AccountTabs() {
    const { setCredential, credential, login } = useGoogleLogin();

    useEffect( () => {
      (async () => {
        if (credential.credential && credential.select_by && credential.clientId) {
          try {
            await login();
          } catch (error) {
            console.error("Login failed:", error);
        }
        }
      })();
     
    }, [credential, login]);


    return <Tabs defaultValue="account" className="w-[400px]">
      <TabsList>
        <TabsTrigger value="account">Login</TabsTrigger>
        <TabsTrigger value="sign up">Sign Up</TabsTrigger>
      </TabsList>
      <TabsContent value="account">
        <LoginForm />
        <GoogleLogin
          onSuccess={async credentialResponse => {
            const {
              credential,
              select_by,
              clientId,
            } = credentialResponse;
            
            if (credential && select_by && clientId) {
              setCredential({
                credential,
                select_by,
                clientId,
              });
          }
        
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        />
        <p className="px-8 text-center text-sm text-muted-foreground pt-4">
          By signing in, you agree to our{" "}<Terms />.
        </p>
      </TabsContent>
      <TabsContent value="sign up">
        <SignUpForm />
      </TabsContent>
    </Tabs>;
  }
  