import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { signupAction } from "@/actions/authActions";
import { Terms } from "@/components/account"


const signUpFormSchema = z
  .object({
    email: z.string().email({ message: "Invalid email address" }),
    password1: z.string().min(8, { message: "Password must be at least 8 characters" }),
    password2: z.string(),
    agreeToTerms: z.boolean().refine(val => val === true, {
      message: "You must agree to the terms and conditions",
    }),
  })
  .refine((data) => data.password1 === data.password2, {
    message: "Passwords don't match",
    path: ["password2"], // path of error
  });

export const SignUpForm: React.FC = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof signUpFormSchema>>({
    resolver: zodResolver(signUpFormSchema),
    defaultValues: {
      email: '',
      password1: '',
      password2: '',
      agreeToTerms: false,
    }
  })

  const handleSubmit = async (values: z.infer<typeof signUpFormSchema>) => {
    try {
      const response = await signupAction(values);
      if (response.ok) {
        navigate("/verify-email");
      } else {
        // Handle each error case. Maybe use one of the react query libraries to handle this
        console.error("Signup failed:", response);
        setError("Invalid email or password");
      }
    } catch (error) {
      console.error("Signup failed:", error);
      setError("Signup failed. Please try again later.");
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="shadow-md rounded px-8 pt-6 pb-8 mb-4 space-y-6">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password1"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input placeholder="******************" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password2"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Repeat password</FormLabel>
              <FormControl>
                <Input placeholder="******************" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="agreeToTerms"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Checkbox checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
              <FormLabel className="pl-2">I agree to the <Terms />.</FormLabel>
              <FormMessage />
            </FormItem>
          )}
        />
        {error && <p className="text-red-500 text-xs italic">{error}</p>}
        <div className="flex items-center justify-between">
          <Button type="submit">Sign up</Button>
        </div>
      </form>
    </Form>
  );
};
