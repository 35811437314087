import { WithSidebar } from "@/hoc/withSidebar";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import './App.css'
import { Report } from '@/components/report/Report';
import { Sidebar } from "@/components/sidebar/Sidebar";
import { LineChart } from "@/components/report/LineChart";


export function ReportPage() {

  return (
        <Tabs defaultValue="table">
          <WithSidebar
            sidebarHeader={() => (<TabsList>
                <TabsTrigger value="table">Table</TabsTrigger>
                <TabsTrigger value="chart">chart</TabsTrigger>
              </TabsList>)}
            sidebarContent={() => <Sidebar/>}>
              <TabsContent value="table">
                <Report/>
              </TabsContent>
              <TabsContent value="chart">
                <LineChart/>
              </TabsContent>
          </WithSidebar>
        </Tabs>
  )
}
