import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { googleAuthAction } from "@/actions/authActions";
import { useAuth } from "@/context/Auth/useAuth";

export const useGoogleLogin = () => {
  const [credential, setCredential] = useState({
      credential: "",
      clientId: "",
      select_by: "",
  });
  const [error, setError] = useState("");
  const { setIsAuthenticated } = useAuth();

  // @todo the docs suggest using redirect in loaders and actions instead of this hook
  // https://reactrouter.com/en/main/hooks/use-navigate
  const navigate = useNavigate();

  const login = async () => {
    try {
      console.log(credential);
      const response = await googleAuthAction(credential);
      if (response.ok) {
        setIsAuthenticated(true);
        // this will need to redirect to the page the user was trying to access if we add more routes
        navigate("/report");
      } else {
        // Handle each error case. Maybe use one of the react query libraries to handle this
        setError("Invalid email or password");
      }
    } catch (error) {
      console.error("Login failed:", error);
      setError("Login failed. Please try again later.");
    }
  };

  return {
    credential,
    setCredential,
    error,
    login,
  };
};
