import { CategoryList } from "@/components/report/CategoryList";
import { FileUpload } from "@/components/upload/FileUpload";

import { Separator } from "@/components/ui/separator"
import { Button } from "@/components/ui/button"
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTrigger,
} from "@/components/ui/sheet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport } from '@fortawesome/free-solid-svg-icons'

export const Sidebar: React.FC = () => {
  return (
  <div className="p-4 flex-col h-full">
    <CategoryList />
    {/* <div className="flex-grow p-4" /> */}
    <div className="flex-grow p-4" />
    <div className="absolute bottom-0 left-0 right-0 p-4">
    <Separator className="mb-4"/>
    <Sheet>
      <SheetTrigger asChild>
        <Button variant={"outline"} className="w-full">
          <FontAwesomeIcon icon={faFileImport} />
          Upload Results
          </Button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetDescription>
            Adding a PDF of your blood test results will help you track changes over time.
          </SheetDescription>
        </SheetHeader>
        <FileUpload />
      </SheetContent>
    </Sheet>
  </div>
  </div>)
};
