
import { isAuthenticatedAction } from "@/actions/authActions";

const checkAuth = async () => {
  const response = await isAuthenticatedAction();
  if (response.ok) {
    const { isAuthenticated } = await response.json();
    return isAuthenticated;
  }
  return false;
};

// let isAuthenticatedFlag : boolean | undefined = undefined;

export const isAuthenticatedCheck = async () => {
  let isAuthenticatedFlag = localStorage.getItem("isAuthenticatedFlag");
  if (isAuthenticatedFlag === null) {
      const authFlag = await checkAuth();
      setIsAuthenticatedCheck(authFlag);
      isAuthenticatedFlag = authFlag;
  }
  return !!isAuthenticatedFlag;
};

export const setIsAuthenticatedCheck = async (isAuthenticatedFlag: boolean) => {
  if (isAuthenticatedFlag) {
    localStorage.setItem("isAuthenticatedFlag", isAuthenticatedFlag.toString());
  } else {
    localStorage.removeItem("isAuthenticatedFlag");
  }
}
