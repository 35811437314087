import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CategoryProvider } from "@/context/category/CategoryProvider";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AuthProvider } from "@/context/Auth/AuthProvider";

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {

    const queryClient = new QueryClient();
    // https://docs.stripe.com/sdks/stripejs-react#elements-provider
    const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || '');

return (
    <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={import.meta.env.VITE_SOCIAL_AUTH_GOOGLE_CLIENT_ID || ''}>
            <AuthProvider>
                <CategoryProvider>
                    <Elements stripe={stripePromise}>
                            {children}
                    </Elements>
                </CategoryProvider>
            </AuthProvider>
        </GoogleOAuthProvider>
    </QueryClientProvider>
    )
};
