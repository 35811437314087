import { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";


export const Subscribe = () => {
  const navigate = useNavigate();
  const {
    state: {
      clientSecret,
    }
  } = useLocation();

  const [name, setName] = useState('');
  const [messages, _setMessages] = useState('');

  // helper for displaying status messages.
  const setMessage = (message: string) => {
    _setMessages(`${messages}\n\n${message}`);
  }

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return '';
  }

  // When the subscribe-form is submitted we do a few things:
  //
  //   1. Tokenize the payment method
  //   2. Create the subscription
  //   3. Handle any next actions like 3D Secure that are required for SCA.
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      setMessage('Card element not found');
      return;
    }

    // Use card Element to tokenize payment details
    const { error } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: name,
        }
      }
    });

    if(error) {
      // show error and collect new card details.
      console.log(error);
      setMessage(error.message ?? 'An unknown error occurred');
      return;
    }
    navigate('/subscriptions', { replace: false });
  }

  return (
    <Card>
        <CardHeader>
            <CardTitle>Subscribe</CardTitle>
            <CardDescription>
                {`${import.meta.env.VITE_MODE}` === 'DEV' && (
                  <div>
                    <p>
                      Try the successful test card: <span>4242424242424242</span>.
                    </p>
                    <p>
                      Try the test card that requires SCA: <span>4000002500003155</span>.
                    </p>
                    <p>
                      Use any <i>future</i> expiry date, CVC,5 digit postal code
                    </p>

                  </div>
                )}
            </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Full name
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                id="name"
                value={name} onChange={(e) => setName(e.target.value)}
              />
            </label>

            <div>
            <CardElement />
            </div>
            <Button className="mt-4">
              Subscribe
            </Button>

            <div>{messages}</div>
          </form>
        </CardContent>
    </Card>
  )
}