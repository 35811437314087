
import React from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "@/routing";
import ReactGA from 'react-ga';

const TRACKING_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_KEY || "UA-XXXXX-X";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  return (
    <React.StrictMode>
      <RouterProvider router={router}/>
    </React.StrictMode>
  );
};

export default App;