import { APP_NAME } from "@/config"

export const LeftSide: React.FC = () => {
    return (
        <div className="relative hidden h-full flex-col p-10 text-white bg-zinc-800 lg:flex  items-center justify-center">
          <div className="relative mx-auto px-12">
            <blockquote className="space-y-2">
              <p className="text-lg">
                &ldquo;Copying my lab results into a spreadsheet was driving me nuts. It was worse when I was traveling, and using different labs where they use different names for the same tests. {APP_NAME} keeps my results consistent and comparable no matter where I get them done. &rdquo;
              </p>
              <footer className="text-sm text-right">Anonymous User</footer>
            </blockquote>
          </div>
        </div>
    )
}