import { useQuery } from "@tanstack/react-query";
import { getSubscriptionEndAction } from "@/actions/authActions";


export const useSubscription = () => {

    const queryFn = async () => {
        const response = await getSubscriptionEndAction()
        if (!response.ok) {
        throw new Error('Network response was not ok')
        }
        return response.json()
    }
    
    const now = new Date();

    const { data, error, isError } = useQuery({
        queryKey: ["subscription-end"],
        queryFn: queryFn
    });

    if (isError) {
        console.log("error", error);
    }
    const subscriptionEnd = data?.subscriptionEnd;
    const isSubscribed = subscriptionEnd && Number(subscriptionEnd) > now.getTime();

    return {
        isSubscribed
    }
}
