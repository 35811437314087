import React from "react";
import { useCategory } from "@/context/category/useCategory";
import { FileUpload } from "../upload/FileUpload";
import { useReport } from "@/hooks/useReport";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { RangeString } from "./components/RangeString";


export const Report: React.FC = () => {
  const { activeCategory } = useCategory();
  const { markers, error, allDates } = useReport();
  
  if (error) {
    console.log("error", error);
    return <></>;
  }

  if (!markers || !activeCategory) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <FileUpload />
      </div>
    );
  }


// Define the return type of the transformData function
interface TransformedData {
  [key: string]: {
    category: string;
    range_less_than: number | null;
    range_greater_than: number | null;
    units: string | null;
    results: {
      [date: string]: number | boolean | string;
    };
  };
}

  // The desired transformation function
function transformData(markers: Marker[]) {
  return markers.reduce((acc, marker) => {
    if (marker.category !== activeCategory) {
      return acc;
    }

    // If the marker name doesn't exist yet, create an empty object for it
    if (!acc[marker.name]) {
      acc[marker.name] = {
        category: marker.category,
        range_less_than: marker.range_less_than,
        range_greater_than: marker.range_greater_than,
        units: marker.units,
        results: {},
      };
    }

    if (!acc[marker.name].results[marker.datetime]) {
      acc[marker.name].results[marker.datetime] = marker.value;
    }

  const resultKeys = Object.keys(acc[marker.name].results);
  const markerDates = resultKeys.map(dateStr => new Date(dateStr));
  const maxDate = new Date(Math.max(...markerDates.map(date => date.getTime())));

  // Use ranges from the greatest date that has them
  resultKeys.forEach(dateStr => {
    if (new Date(dateStr).getTime() === maxDate.getTime()) {
      if (marker.range_less_than) {
        acc[marker.name].range_less_than = marker.range_less_than;
      }
      if (marker.range_greater_than) {
        acc[marker.name].range_greater_than = marker.range_greater_than;
      }
      if (marker.units) {
        acc[marker.name].units = marker.units;
      }
      if (marker.category) {
        acc[marker.name].category = marker.category;
      }
    }
  });

    return acc;
  }, {} as TransformedData);
}

// Transform the data
const transformedData = transformData(markers);

  
  return (
    <Table>
  <TableHeader>
    <TableRow key="asfafsasfasfasfafs">

     <TableHead className="w-[150px]">Test Name</TableHead>
     <TableHead className="w-[50px]">Range</TableHead>
     <TableHead className="w-[50px]">Units</TableHead>
        {allDates.map(date => (
            <TableHead className="w-[100px]" key={date}>{new Date(date).toLocaleDateString()}</TableHead>
          ))}
    </TableRow>
  </TableHeader>
  <TableBody>
  {Object.entries(transformedData).map(([testName, testData]) => {

      return (

    <TableRow key={testName}>
          <TableCell className="font-medium">{testName}</TableCell>
          <TableCell>
            <i>
            <RangeString  range_less_than={testData.range_less_than} range_greater_than={testData.range_greater_than} />
            </i>
          </TableCell>
          <TableCell>
          {testData.units && <sub> {testData.units}</sub>}
          </TableCell>
          {/* <TableCell>{testData.range_low && `${testData.range_low} - ${testData.range_high}`}{testData.units &&  ` <sub>${testData.units}<sub/>`}</TableCell> */}
          {allDates.map((date) => {
            const result = 
                testData.results[date] === false
                   ? "Negative"
                   : testData.results[date]

            let className = '';
            if (result && typeof result === 'number') {
              // Beware of JS type bullshit here
              if (typeof testData.range_less_than === 'number' && result > testData.range_less_than) {
                className = 'text-red-500';
              } else if (typeof testData.range_greater_than === 'number' && result < testData.range_greater_than) {
                className = 'text-red-500';
              }
            }
            
            return <TableCell className={className} key={`${testName}${date}`}>
              {result}
            </TableCell>
  })}
        </TableRow>
      );
    })}
   
  </TableBody>
  </Table>
  );
};
