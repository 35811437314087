import { createBrowserRouter } from "react-router-dom";
import { LoginPage, ConfirmEmailPage, PasswordResetPage, PasswordResetConfirmPage, ReportPage, SubscriptionsPage, SubscribePage, CancelPage, LandingPage, PrivacyPolicyPage, TermsOfServicePage} from "@/pages";
import { loginLoader, protectedLoader } from "./loaders.ts";
import { AppLayout, LandingLayout } from "./components.tsx";


export const router = createBrowserRouter([
  {
    path: "/",
    Component: LandingLayout,
    children: [
      {
        index: true,
        Component: LandingPage
      },
      {
        path: "privacy",
        Component: PrivacyPolicyPage,
      },
      {
        path: "terms",
        Component: TermsOfServicePage,
      },
    ]
  },
  {
    path: "/",
    Component: AppLayout,
    children: [
      {
        path: "login",
        Component: LoginPage,
        loader: loginLoader
      },
      {
        path: "verify-email",
        Component: ConfirmEmailPage,
        loader: loginLoader
      },
      {
        path: "password/reset",
        Component: PasswordResetPage,
        loader: loginLoader
      },
  {
        path: "password/reset/confirm/:uid/:token",
        Component: PasswordResetConfirmPage,
        loader: loginLoader
      },
      {
        path: "report",
        Component: ReportPage,
        loader: protectedLoader
      },
      {
        path: "subscriptions",
        Component: SubscriptionsPage,
        loader: protectedLoader
      },
      {
        path: "subscribe",
        Component: SubscribePage,
        loader: protectedLoader
      },
      {
        path: "cancel",
        Component: CancelPage,
        loader: protectedLoader
      },
      // {
      //   path: "document/:documentId",
      //   Component: PDFPage,
      //   loader: protectedLoader
      // }
    ]
  }
]);
