import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { isAuthenticatedCheck } from "@/auth/isAuthenticatedCheck";

export async function loginLoader() {
  if (await isAuthenticatedCheck()) {
    return redirect("/report");
  }
  return null;
}

export async function protectedLoader({ request }: LoaderFunctionArgs) {
  // If the user is not logged in and tries to access `/protected`, we redirect
  // them to `/login` with a `from` parameter that allows login to redirect back
  // to this page upon successful authentication
  if (await isAuthenticatedCheck() == false) {
    const params = new URLSearchParams();
    params.set("from", new URL(request.url).pathname);
    return redirect("/login?" + params.toString());
  }
  return null;
}
