import { CSRFTokenProvider } from "@/auth/CSRFTokenProvider";

// Return an array of markers
export const getReportAction = async () => {

  const csrftoken = await CSRFTokenProvider.getCSRFToken() || '';

  const result = await fetch(`${import.meta.env.VITE_HOST}/api/markers/`, {
    method: "GET",
    credentials: 'include',
    headers: {
      'X-CSRFToken': csrftoken,
    },
});
  return await result.json();
};