import { createContext } from "react";

interface CategoryContextProps {
  activeCategory: Category | undefined;
  setActiveCategory: (Category: Category) => void;
  categories: Category[] | undefined;
  setCategories: (Categories: Category[]) => void;
}

export const CategoryContext = createContext<
  CategoryContextProps | undefined
>(undefined);
