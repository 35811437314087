import { LeftSide } from "@/components/account"


export function ConfirmEmailPage() {
  return (
    <div className="container flex-col h-full items-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0 ">
        <LeftSide />
        <div className="lg:p-8">
          <div className="mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]">
            <div className="flex flex-col space-y-2 text-center">
              <h1 className="text-2xl font-semibold tracking-tight">
                Verify email
              </h1>
              <p className="text-sm text-muted-foreground">
              </p>
            </div>
            <p>Please check your email for verification instructions.</p>
          </div>
        </div>
      </div>
  )
}
